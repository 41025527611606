.navbar-container {
  position: fixed;
  min-width: 100vw;
  margin-top: 0px;
  margin-bottom: 0px;
  max-height: 105px;
  min-height: 105px;
  z-index: 99999;
  top: 0px;
  background-color: transparent;
  background-image: var(--navbar-gradient);
  box-shadow: 0px 2px 5px rgba(0, 0, 0, 0.411);
}

.navbar-inner {
  display: flex;
  justify-content: space-between;
  align-items: center;
  min-width: 100vw;
  max-width: 100vw;
  height: 105px;
  max-height: inherit;
}

#logo-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
  background-color: transparent;
  min-height: 105px;
  margin: 0 auto 0 4.5vw;
  transition: all 0.2s cubic-bezier(0.25, 1, 0.5, 1);
}

.logo {
  align-self: center;
  padding: 0;
  margin: 0;
  height: 60px;
  width: 214px;
  background-color: transparent;
  transition: all 0.15s cubic-bezier(0.25, 1, 0.5, 1);
}

#division-name {
  font-family: var(--default-font);
  font-size: calc(8px + 0.6vw);
  font-weight: 400;
  color: var(--argus-black);
  background-color: transparent;
  padding: 0 !important;
  margin: 0;
  transition: all 0.15s cubic-bezier(0.25, 1, 0.5, 1);
}

.nav-links-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  background-color: transparent;
  height: inherit;
  margin: 0 55px 0 0;
  transition: all 0.2s cubic-bezier(0.25, 1, 0.5, 1);
  width: fit-content;
}

.nav-links-container#hide-links {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  margin: 0 35px 0 0;
  transition: all 0.2s cubic-bezier(0.25, 1, 0.5, 1);
}

#nav-links-ul {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  line-height: normal;
  height: 105px;
  gap: 35px;
  margin: 0;
  padding: 0;
  transition: all 0.15s cubic-bezier(0.25, 1, 0.5, 1);
}

.nav-links-li {
  display: flex;
  align-items: center;
  list-style: none;
  height: inherit;
  font-family: var(--varela-round-open-sans-font);
  font-size: clamp(12px, 60%, 14px);
  font-weight: 400;
  text-shadow: none !important;
  transition: all 0.15s cubic-bezier(0.25, 1, 0.5, 1);
}

.nav-links-link {
  display: flex;
  align-items: center;
  color: rgb(255, 255, 255);
  text-decoration: none;
  font-weight: 450;
  padding: 5px;
  text-rendering: optimizeLegibility;
  text-shadow: none !important;
  margin: 0;
}
.nav-links-li.active-listItem {
  border-bottom: 2px solid rgba(217, 217, 217, 0.926);
}

.nav-links-li:hover {
  cursor: pointer;
  text-decoration: none;
  color: rgb(240, 240, 240);
  background-color: transparent;
  border-bottom: 2px solid rgb(452, 245, 245);
}

#hamburger {
  display: flex;
  align-self: center;
  margin: 0px;
  stroke: rgb(255, 255, 255);
  pointer-events: all !important;
  transition: all 0.15s cubic-bezier(0.25, 1, 0.5, 1);
}

#hamburger:hover {
  cursor: pointer;
}

#x-btn {
  margin-right: 0px;
  stroke: rgb(255, 255, 255);
  font-weight: var(--default-font-weight);
}

#x-btn:hover {
  cursor: pointer;
}

@media screen and (width < 1025px) {
  #logo-container {
    min-height: 95px;
  }

  .logo {
    height: 50px !important;
    width: 165px !important;
  }

  #division-name {
    font-size: 14px !important;
  }

  #hamburger {
    height: 30px;
    width: 30px;
  }
}

@media screen and (width < 1120px) {
  #logo-container {
    margin-left: calc(100px - 2rem);
  }
}
