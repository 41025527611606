#tooltip-sign3x3,
#tooltip-signStand {
  left: 840px !important;
}

#requestStatus-tooltip .dashboard-tooltip {
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  background-color: rgba(64, 64, 64, 0.945);
  color: var(--p-white);
  font-weight: var(--default-font-weight);
  font-family: var(--default-font);
  text-align: center;
  text-shadow: none;
  height: min-content;
  text-align: center;
}

.dashboard-tooltip-popper {
  text-align: center;
  min-height: min-content;
  max-height: 100px !important;
  max-width: 450px !important;
  width: fit-content;
}

#requestStatus-tooltip .dashboard-tooltip .dashboard-arrow {
  color: rgba(64, 64, 64, 0.945);
}

#services-tooltip .dashboard-tooltip,
#contacts-tooltip .dashboard-tooltip,
#files-tooltip .dashboard-tooltip,
#archive-tooltip .dashboard-tooltip,
#saved-project-tooltip .dashboard-tooltip {
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  font-size: 15px;
  background-color: rgba(64, 64, 64, 0.945);
  color: var(--p-white);
  font-weight: var(--default-font-weight);
  font-family: var(--default-font);
  text-align: center;
  text-shadow: none;
  height: min-content;
  text-align: center;
}

#services-tooltip .dashboard-tooltip-popper,
#contacts-tooltip .dashboard-tooltip-popper,
#files-tooltip .dashboard-tooltip-popper,
#archive-tooltip .dashboard-tooltip-popper,
#saved-project-tooltip .dashboard-tooltip-popper {
  text-align: center;
  min-height: min-content;
  max-height: 100px !important;
  max-width: 100px !important;
  width: fit-content;
}

#services-tooltip .dashboard-tooltip .dashboard-arrow,
#contacts-tooltip .dashboard-tooltip .dashboard-arrow,
#files-tooltip .dashboard-tooltip .dashboard-arrow,
#archive-tooltip .dashboard-tooltip .dashboard-arrow,
#saved-project-tooltip .dashboard-tooltip .dashboard-arrow {
  color: rgba(64, 64, 64, 0.945);
}

/* | Radio Group Tooltips */
#rg-tooltip .radio-group-tooltip {
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  background-color: var(--app-bar-bg);
  color: var(--p-white);
  font-weight: var(--default-font-weight);
  font-family: var(--default-font);
  text-align: center;
  text-shadow: none;
  height: min-content;
  text-align: center;
}

/* + Popper */
.radio-group-popper {
  text-align: center;
  height: min-content;
}

/*  + Arrow */
#rg-tooltip .radio-group-tooltip .radio-group-arrow {
  color: var(--app-bar-bg);
}

#calculator-tooltip .calculator-tooltip {
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  background-color: var(--app-bar-bg);
  color: var(--p-white);
  font-weight: var(--default-font-weight);
  font-family: var(--default-font);
  text-align: center;
  text-shadow: none;
  height: min-content;
  text-align: center;
}

/* + Popper */
.calculator-popper {
  text-align: center;
  height: min-content;
}

/* + Arrow */
#calculator-tooltip .calculator-tooltip .calculator-arrow {
  color: var(--app-bar-bg);
}

/* | Radio Field Tooltips */
#rf-tooltip .radio-field-tooltip {
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  background-color: var(--app-bar-bg);
  color: var(--p-white);
  font-weight: var(--default-font-weight);
  font-family: var(--default-font);
  text-align: center;
  text-shadow: none;
  height: min-content;
  text-align: center;
}

/* + Popper */
.radio-field-popper {
  text-align: center;
  height: min-content;
}

/* | Text Field Tooltips */
#tf-tooltip .text-field-tooltip,
#pf-tooltip .text-field-tooltip,
#ef-tooltip .text-field-tooltip,
#s-tooltip .slider-tooltip,
#attach-tooltip .attach-file-tooltip {
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  background-color: var(--app-bar-bg);
  color: var(--p-white);
  font-weight: var(--default-font-weight);
  font-family: var(--default-font);
  text-align: center;
  text-shadow: none;
  height: min-content;
  text-align: center;
  letter-spacing: 0.5px;
  width: 100%;
}

#saved-indicator-tooltip .saved-indicator-tooltip {
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  background-color: var(--app-bar-bg);
  color: var(--p-white);
  font-weight: var(--default-font-weight);
  font-family: var(--default-font);
  text-align: center;
  text-shadow: none;
  height: min-content;
  text-align: center;
  letter-spacing: 0.5px;
  width: 100%;
}

#color-legend-tooltip .color-legend-tooltip {
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  background-color: var(--app-bar-bg);
  color: var(--p-white);
  font-weight: var(--default-font-weight);
  font-family: var(--default-font);
  text-align: center;
  text-shadow: none;
  height: min-content;
  text-align: center;
  letter-spacing: 0.5px;
  width: 100%;
}

/* + Popper */
.text-field-popper,
.slider-tooltip,
.attach-popper,
.saved-indicator-popper,
.color-legend-popper {
  text-align: center;
  width: auto;
  height: min-content;
}

#tf-tooltip .text-field-tooltip .text-field-arrow,
#pf-tooltip .text-field-tooltip .text-field-arrow,
#ef-tooltip .text-field-tooltip .text-field-arrow,
#s-tooltip .slider-tooltip .slider-arrow,
#rf-tooltip .radio-field-tooltip .radio-field-arrow,
#attach-tooltip .attach-file-tooltip .attach-arrow,
#color-legend-tooltip .color-legend-tooltip .color-legend-arrow {
  color: var(--app-bar-bg);
}

/*  | Scope of Work Tooltip */
#sow-tooltip .sow-tooltip {
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  background-color: var(--app-bar-bg);
  color: rgb(240, 240, 240);
  font-weight: var(--default-font-weight);
  font-family: var(--default-font);
  text-align: center;
  text-shadow: none;
  height: min-content;
  text-align: center;
  letter-spacing: 0.5px;
  width: 100%;
}

/* + Popper */
.sow-popper {
  text-align: center;
  width: auto;
  height: min-content;
}

#sow-tooltip .sow-tooltip .sow-arrow {
  color: var(--app-bar-bg);
}

/* | Clear Text Field Icon Tooltip */
#clear-btn-tooltip .tooltip-clear-btn,
#calendar-tooltip .tooltip-calendar {
  display: flex;
  background-color: rgba(58, 58, 58, 0.915);
  color: white;
  font-size: 13px;
  max-width: 120px;
  min-width: 100px;
  text-align: center;
  align-self: center;
  justify-self: center;
  padding: 5px;
  justify-content: center;
  align-items: center;
}

#clear-btn-tooltip .tooltip-top {
  position: relative;
  top: 3px !important;
}

#calendar-tooltip .calendar-tooltip-top {
  position: relative;
  top: 3px !important;
}

/* | SWITCH TOOLTIP */
#switch-tooltip .archive-switch-tooltip {
  display: flex;
  align-self: center;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  background-color: rgba(170, 170, 170, 0.845);
  color: rgb(35, 35, 35);
  font-weight: var(--default-font-weight);
  font-family: var(--default-font);
  text-align: center;
  text-shadow: none;
  height: min-content;
  text-align: center;
  letter-spacing: 0.4px;
  width: 100%;
}

/* + Popper */
.archive-switch-popper,
.archive-switch-tooltip {
  text-align: center;
  width: auto;
  height: min-content;
}

#switch-tooltip .archive-switch-tooltip .archive-switch-arrow {
  color: rgba(170, 170, 170, 0.845);
}

/* % MEDIA QUERIES */
@media screen and (1450px <= width < 1850px) {
  .radio-field-popper {
    max-width: 305px;
  }

  #tf-tooltip .text-field-tooltip,
  #pf-tooltip .text-field-tooltip,
  #ef-tooltip .text-field-tooltip,
  #s-tooltip .slider-tooltip,
  #attach-tooltip .attach-file-tooltip {
    font-size: 13px;
    width: auto;
    max-width: 320px;
  }
}

@media screen and (1850px <= width) {
  #requestStatus-tooltip .dashboard-tooltip {
    font-size: 15px;
    padding: 5px;
    width: auto;
    max-width: 550px;
  }

  #requestStatus-tooltip .dashboard-tooltip-popper {
    padding: 0px;
    max-width: 550px;
  }

  #rg-tooltip .radio-group-tooltip {
    font-size: 14px;
    padding: 5px;
    width: auto;
    max-width: 340px;
  }

  .radio-group-popper {
    padding: 0px;
    max-width: 340px;
  }

  #rg-tooltip {
    display: flex;
    justify-content: center;
    max-width: 340px;
  }

  #tf-tooltip .text-field-tooltip,
  #pf-tooltip .text-field-tooltip,
  #ef-tooltip .text-field-tooltip,
  #s-tooltip .slider-tooltip,
  #attach-tooltip .attach-file-tooltip {
    font-size: 14px;
    width: auto;
    max-width: 340px;
  }

  #tf-tooltip,
  #pf-tooltip,
  #ef-tooltip,
  #s-tooltip,
  #attach-tooltip {
    display: flex;
    justify-content: center;
    max-width: 340px;
  }

  .text-field-popper,
  .slider-tooltip,
  .attach-popper {
    max-width: 340px;
  }

  #sow-tooltip .sow-tooltip {
    font-size: 14px;
    padding: 5px;
    width: 100%;
    max-width: 350px;
  }

  .sow-popper {
    padding: 0px;
    max-width: 350px;
    width: 350px;
  }

  #switch-tooltip .archive-switch-tooltip {
    font-size: 13px;
    padding: 5px;
    width: 100%;
    max-width: 320px;
    outline: 1px solid rgba(61, 61, 61, 0.48);
  }

  .archive-switch-popper {
    padding: 0px;
    max-width: 320px;
    width: 320px;
  }

  #switch-tooltip .archive-switch-top {
    position: relative;
    bottom: 8px !important;
  }
}
