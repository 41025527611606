@import url("https://fonts.googleapis.com/css2?family=Open+Sans:wght@300;400;500;600;700&family=Roboto:wght@300;400;500;700;900&family=Varela+Round&display=swap");

:root {
  /* DEFAULT COLORS */
  --root-bg: rgb(134, 181, 205);
  --main-blue: rgb(58, 133, 162);
  --argus-blue-light: rgb(125, 177, 198);
  --argus-blue-dark: rgb(45, 109, 135);
  --argus-blue-darker: rgba(38, 92, 113, 0.949);
  --argus-black: rgb(46, 51, 53);
  --argus-black-light: rgb(30, 30, 30);
  --argus-white: rgb(235, 235, 235);
  --app-bar-bg: rgba(46, 51, 53, 0.992);
  --timeline-bg: rgb(41, 45, 46);
  --navbar-gradient: linear-gradient(
    130deg,
    rgb(255, 255, 255) 0%,
    rgb(16, 114, 151) 100%
  );
  /* ~ FONTS */
  --roboto-font: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Helvetica Neue", Arial, sans-serif;
  --default-font: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto",
    "Helvetica Neue", Arial, sans-serif;
  --varela-round-font: "Varela Round", sans-serif;
  --varela-round-open-sans-font: "Varela Round", "Open Sans", sans-serif;
  --default-font-weight: 700;
  --font-weight-light: 600;
  --font-weight-bold: 900;
  /* ~ CONTAINERS */
  --outline-color: rgb(217, 237, 245);
  --input-container-box-shadow: rgba(0, 0, 0, 0.521) 0px 3px 12px;
  --small-container-box-shadow: rgba(0, 0, 0, 0.422) 0px 2px 8px;
  /* ~ MAIN HEADERS */
  --main-header-color: rgb(235, 235, 235);
  --header-text-shadow: 2px 3px 3px rgba(55, 55, 55, 0.3);
  --dashboard-header-color: rgb(48, 48, 48);
  /* ~ Default Labels */
  --label-color: rgba(248, 251, 255, 0.967);
  --form-label-text-shadow: var(--form-label-text-color);
  /* ~ Input Labels */
  --input-label-color: rgb(125, 125, 125);
  --shrink-label-color: rgb(240, 240, 240);
  --p-white: rgb(236, 236, 236);
  --p-yellow: rgb(246, 218, 34);
  --folder-name: rgb(56, 56, 56);
  --file-details: rgb(35, 35, 35);
  /* ~ Inputs */
  --input-white-bg: rgb(244, 244, 244);
  --input-text-color: rgb(20, 20, 20);
  --input-box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 1px,
    rgba(3, 8, 15, 0.09) 0px 1px 1px, rgba(0, 0, 0, 0.217) 0px 4px 5px,
    rgba(2, 1, 15, 0.09) 0px 2px 3px, rgba(0, 0, 0, 0.09) 0px 6px 6px;
  --input-outline-required: rgb(255, 32, 32);
  --input-outline-revised: rgb(253, 152, 0);
  --input-place-holder-color: rgb(125, 125, 125, 0.752);
  /* ~ RADIO BTNS */
  --radio-box-shadow: rgba(0, 0, 0, 0.06) 0px 2px 1px,
    rgba(0, 0, 0, 0.09) 0px 4px 2px, rgba(0, 0, 0, 0.09) 0px 2px 4px,
    rgba(0, 0, 0, 0.09) 0px 4px 8px, rgba(0, 0, 0, 0.09) 0px 6px 5px;
  --radio-btn-red: rgb(225, 36, 36);
  /*  - Icons */
  --close-icon-color: rgb(255, 0, 0);
  /* - BTNS */
  --btn-light-blue: rgb(30, 171, 203);
  --btn-light-blue-hover: rgb(2, 153, 186);
  --btn-red: rgb(207, 46, 46);
  --btn-red-hover: rgb(165, 38, 38);
  --btn-green: rgb(30, 209, 27);
  --btn-green-hover: rgb(6, 192, 3);
  --btn-white-text: rgb(240, 240, 240);
  --disabled-btn-color: rgba(231, 231, 231, 0.601);
  --disabled-btn-bg: rgba(185, 185, 185, 0.549);
}

:root.darkMode {
  --root-bg: rgb(55, 58, 59);
  --file-details: var(--p-white);
}

label,
p,
h1,
h2,
h3,
h4,
h5,
div,
span,
image,
img,
svg {
  user-select: none !important;
}

body {
  margin: 0;
  padding: 0;
  background-color: var(--root-bg);
}

#root {
  min-width: 100vw !important;
  min-height: 100vh !important;
  height: auto !important;
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  text-rendering: optimizeLegibility;
  font-family: var(--default-font);
}
