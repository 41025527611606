.app {
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  min-height: 100vh;
  height: 100%;
  min-width: 100vw;
  margin: 0 !important;
  padding: 0 !important;
  font-family: var(--default-font);
  text-rendering: optimizeLegibility;
}
