#sign-in-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
  top: 220px;
  padding-bottom: 0px;
  width: 100%;
  max-width: 90%;
  height: auto;
  text-align: center;
  border: 4px solid var(--outline-color);
  background-color: var(--main-blue);
  border-radius: 5px;
  box-shadow: var(--input-container-box-shadow);
  box-sizing: border-box;
}

#sign-in-header {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  border-bottom: 4px solid var(--outline-color);
  height: fit-content;
  margin: 0;
  padding: 10px 0;
}

#sign-in-heading {
  color: var(--main-header-color);
  font-size: clamp(1.5rem, 1.5vw, 2.5rem);
  font-weight: var(--font-weight-bold);
  text-shadow: var(--header-text-shadow);
  font-family: var(--default-font);
  padding: 0;
  margin: 0;
}

#sign-in-content-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
  min-width: 100%;
  height: 100%;
  flex-grow: 1;
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

#sign-in-p-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  min-width: 95%;
  max-width: 95%;
  height: max-content;
  margin: 0;
  box-sizing: border-box;
}

#sign-in-p {
  min-width: 100%;
  max-width: 100%;
  color: var(--label-color);
  text-shadow: none;
  margin: 0;
  padding: 0;
  text-align: center;
  overflow-wrap: break-word;
  box-sizing: border-box;
  font-weight: var(--default-font-weight);
  font-family: var(--default-font) !important;
  font-size: clamp(0.9rem, 1vw, 1.1rem);
}

.input-password-container {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 0;
  padding: 15px;
}

#company-code-label {
  font-weight: var(--default-font-weight);
  font-family: var(--default-font);
  color: var(--label-color);
  text-shadow: var(--form-label-text-color);
  margin-right: auto;
  pointer-events: none;
  user-select: none;
  font-size: clamp(1.1rem, 1.2vw, 1.4rem);
}

.form-sign-in-root {
  box-shadow: var(--input-box-shadow);
  min-width: 325px;
  height: 35px;
  border-radius: 5px;
  background-color: var(--input-white-bg) !important;
}

#password-outlined-input.password-root-input {
  color: var(--input-text-color) !important;
  font-family: var(--default-font);
  text-indent: 8px;
  margin: 0;
  padding: 0;
  box-shadow: none;
  font-weight: var(--default-font-weight) !important;
  border-radius: 5px;
  min-width: 290px;
  height: 100%;
  background-color: var(--input-white-bg) !important;
  font-size: 1.2rem;
}

#password-outlined-input.password-root-input[type="password"] {
  font-family: Verdana;
  letter-spacing: 0.1rem;
  font-size: clamp(0.7rem, 0.8vw, 0.85rem);
}

.password-root {
  background-color: var(--input-white-bg) !important;
  background-color: transparent !important;
  border: none;
  box-shadow: none;
  height: 35px;
}

.password-root,
.password-notchedOutline {
  border: none !important;
  outline: none !important;
}

.password-root,
.password-notchedOutline:focus-within {
  outline: none !important;
  border: none !important;
}

.password-visibility {
  padding: 0px;
  color: #3295b9e4;
}

#show-password {
  opacity: 1;
}

#hide-password {
  opacity: 0.75;
}

.password-visibility.MuiSvgIcon-fontSizeMedium {
  font-size: clamp(1.35rem, 1.4vw, 1.8rem);
  /* font-size: 1.25rem; */
}

#password-icon-button {
  height: 28px;
  width: 28px;
  margin-right: auto;
  padding: 0px;
}

#password-icon-button:hover {
  background-color: rgba(121, 163, 226, 0.281);
}

#password-label {
  color: var(--input-label-color);
  font-family: var(--default-font);
  font-weight: var(--default-font-weight);
  text-shadow: none !important;
  transform: translate(8px, 7px) scale(1);
}

#password-label.password-label-shrink {
  color: var(--shrink-label-color);
  transform: translate(8px, -18px) scale(0.7);
}

#company-password:focus {
  outline: none !important;
  border: none !important;
  background-color: var(--input-white-bg) !important;
}

#sign-in-helper-text {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  bottom: 95px;
  right: 165px;
  height: 25px;
  width: 235px;
}

#sign-in-helper-text-p {
  color: rgb(183, 43, 43);
  text-shadow: 0px 0px 1.5px rgba(163, 50, 50, 0.353);
  font-weight: 650;
  font-size: 13px;
  margin: 0;
  padding: 0;
  width: fit-content;
}

#sign-in-button {
  background-color: #2de100;
  font-size: 15px;
  font-weight: var(--default-font-weight);
  height: 32px;
  width: 90px;
  margin-bottom: 10px;
  padding: 0;
}

#sign-in-button:hover {
  background-color: #32d406;
}

#sign-in-button:disabled,
#sign-in-button.Mui-disabled {
  background-color: rgba(156, 156, 156, 0.549) !important;
  color: rgba(231, 231, 231, 0.601) !important;
  box-shadow: none !important;
  cursor: not-allowed !important;
  pointer-events: auto !important;
}

@media screen and (width < 975px) {
  #sign-in-container {
    min-width: 800px;
    width: 90%;
    max-width: 100%;
    height: 280px;
  }

  .input-password-container {
    width: 95%;
    max-width: 95%;
  }

  .form-sign-in-root {
    min-width: max-content;
    height: 35px;
  }

  .password-root {
    min-width: 350px;
    max-width: 360px;
    height: 35px;
  }

  .password-root-input {
    max-width: 320px !important;
    min-width: 310px;
    font-size: 16px;
  }

  #password-label {
    transform: translate(8px, 7px) scale(0.9);
  }
  #password-label.password-label-shrink {
    transform: translate(3px, -16px) scale(0.7);
  }

  #password-icon-button {
    height: 30px;
    width: 30px;
    margin-right: auto;
    padding: 0px;
  }

  #sign-in-button {
    font-size: 14px;
    font-weight: var(--default-font-weight);
    height: 30px;
    width: 82px;
  }
}

@media screen and (975px <= width < 1250px) {
  #sign-in-container {
    width: 85%;
    max-width: 900px;
    min-height: 280px;
  }

  .input-password-container {
    width: 95%;
    max-width: 95%;
  }

  .form-sign-in-root {
    min-width: max-content;
    height: 35px;
  }

  .password-root {
    min-width: 390px;
    max-width: 400px;
  }

  .password-root-input.MuiOutlinedInput-input {
    max-width: 360px;
    min-width: 350px;
    font-size: 18px;
  }

  #password-label {
    transform: translate(8px, 7px) scale(1);
  }
  #password-label.password-label-shrink {
    transform: translate(3px, -18px) scale(0.74);
  }

  #password-icon-button {
    height: 30px;
    width: 30px;
    margin-right: auto;
    padding: 0px;
  }
}

@media screen and (1250px <= width < 1450px) {
  #sign-in-container {
    max-width: 70%;
    height: 350px;
  }

  .input-password-container {
    width: 100%;
    max-width: 90%;
    height: 55px;
  }

  .form-sign-in-root {
    min-width: max-content;
    height: 35px;
  }

  .password-root {
    min-width: 420px;
    max-width: 430px;
    height: 35px;
  }

  .password-root-input {
    max-width: 390px !important;
    min-width: 380px;
    font-size: 18px;
  }
  #password-label {
    transform: translate(8px, 7px) scale(1);
  }
  #password-label.password-label-shrink {
    transform: translate(3px, -18px) scale(0.78);
  }
  #password-icon-button {
    height: 32px;
    width: 32px;
    margin-right: auto;
    padding: 0px;
  }

  #sign-in-button {
    font-size: 15px;
    font-weight: var(--font-weight-bold);
    /* font-weight: var(--default-font-weight); */
    height: 33px;
    width: 85px;
  }
}

@media screen and (1450px <= width < 1850px) {
  #sign-in-container {
    width: 70%;
    max-width: 1000px;
    height: 350px;
  }

  .input-password-container {
    max-width: 95%;
    min-width: 90%;
    height: 55px;
  }

  .form-sign-in-root {
    min-width: max-content;
    height: 40px;
  }

  .password-root {
    min-width: 445px;
    max-width: 455px;
    height: 40px;
  }

  .password-root-input {
    min-width: 400px;
    max-width: 405px !important;
    min-height: 40px;
  }

  #password-label {
    transform: translate(8px, 9px) scale(1.1);
  }
  #password-label.password-label-shrink {
    transform: translate(3px, -19px) scale(0.8);
  }
  #password-icon-button {
    height: 30px;
    width: 30px;
    margin-right: auto;
    padding: 0px;
  }

  #sign-in-button {
    font-size: 17px;
    font-weight: var(--font-weight-bold);
    height: 32px;
    width: 90px;
  }
}

@media screen and (1850px <= width) {
  #sign-in-container {
    width: 70%;
    max-width: 1200px;
    height: 425px;
  }

  .input-password-container {
    max-width: 95%;
    min-width: 95%;
    height: 65px;
  }

  .form-sign-in-root {
    min-width: max-content;
    height: 45px;
  }

  .password-root {
    min-width: 495px;
    max-width: 510px;
    height: 45px;
  }

  #password-outlined-input.password-root-input {
    letter-spacing: 0.75px;
    min-width: 455px;
    max-width: 465px;
    min-height: 40px;
    height: 100%;
    text-indent: 10px;
  }

  #password-outlined-input.password-root-input[type="password"] {
    letter-spacing: 0.18rem;
  }

  #password-label {
    transform: translate(10px, 10px) scale(1.2);
  }
  #password-label.password-label-shrink {
    transform: translate(3px, -19px) scale(0.85);
    letter-spacing: 0.95px !important;
  }
  #password-icon-button {
    height: 32px;
    width: 32px;
    margin-right: auto;
    padding: 0px;
  }

  #sign-in-button {
    font-size: 18px;
    font-weight: var(--font-weight-bold) !important;
    height: 35px;
    width: 95px;
  }
}
